<nav class="header-container">
    <div class="row">
        <div class="col">
            <div ngbDropdown class="d-inline-block">
                <button type="button" class="btn btn-outline-primary" id="userManualDropdown" ngbDropdownToggle>
                    <span 
                        class="menu-icon question-circle"
                    >
                        <i class="bi bi-question-circle-fill"></i>
                    </span>
                    <span class="menu-text">Nutzerhandbuch</span>
                </button>
                <div ngbDropdownMenu aria-labelledby="userManualDropdown" >
                    <button ngbDropdownItem (click)="downloadFile()">
                        <span class="menu-item-text">Herunterladen</span>
                        <span 
                            class="menu-item-icon download"
                        >
                            <i class="bi bi-download"></i>
                        </span>
                    </button>
                    <button ngbDropdownItem (click)="viewInBrowser()">
                        <span class="menu-item-text">Im Browser öffnen</span>
                        <span 
                            class="menu-item-icon"
                        >
                            <i class="bi bi-box-arrow-up-right"></i>
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isAdmin" class="btn-group btn-group-toggle" role="group">
        <input type="radio" [formControl]="switch" [value]="'CAR'" class="btn-check" id="switch_car" autocomplete="off">
        <label class="btn btn-primary" for="switch_car">
            <span>PKW</span>
        </label>

        <input type="radio" [formControl]="switch" [value]="'VAN'" class="btn-check" id="switch_van" autocomplete="off">
        <label class="btn btn-primary" for="switch_van">
            <span>VAN</span>
        </label>
    </div>
    <div class="user-info">
        <span>
            <i class="bi bi-person-fill"></i>
        </span>
        <div>
            <span>{{username}}</span>
        </div>
    </div>     
    <span
        (click)="logout()"
        title="Abmelden"
    >
        <i class="bi bi-box-arrow-right"></i>
    </span>
</nav>
