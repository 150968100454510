<div class='vus-modal'>
    <div class='modal-header close-top-right'>
        <h3 class='modal-title'>
            Ergebnis von
            {{ test.timeStamp |date: 'dd.MM.YYYY, HH:mm:ss' }}
        </h3>
        <button type='button' class='btn close' aria-label='Close'>
            <span
                class="close-icon"
                icon="times"
                (click)='activeModal.dismiss()'
            >
                <i class="bi bi-x"></i>
            </span>
        </button>
    </div>
    <div class='modal-header mt-close'>
        <div class='test-result-section'>
            <div class='test-result'>
                <label class='label' *ngIf='test.successfulTestCases > 0'>
                    <span
                        [style.color]="'#008351'"
                    >
                        <i class="bi bi-circle-fill"></i>
                    </span>
                    {{ test.successfulTestCases }} Bestanden
                </label>
            </div>
            <div class='test-result'>
                <label class='label' *ngIf='test.unsuccessfulTestCases > 0'>
                    <span
                        [style.color]="'#bb1e10'"
                    >
                        <i class="bi bi-circle-fill"></i>
                    </span>
                    {{ test.unsuccessfulTestCases }} Nicht bestanden
                </label>
            </div>
        </div>
    </div>
    <div class='modal-body checkbox-group'>
        <label class='checkbox-container'>
            <input
                type='checkbox'
                [checked]='showOnlyFailed'
                (change)='toggleFilter()'
            />
            <span class='checkmark'></span>
        </label>
        <label class='checkbox-label'>
            Nur nicht bestandene Testfälle anzeigen
        </label>
    </div>
    <div class='modal-body'>
        <ngx-datatable
            #dataTable
            class='material'
            [columnMode]="'flex'"
            [headerHeight]='50'
            [footerHeight]='50'
            rowHeight='auto'
            [rows]='filteredDetails'
            [limit]='dataTableLimit'>

            <ngx-datatable-column
                name='Testfall'
                prop='name'
                [flexGrow]='7'
                [resizeable]='false'
            >
                <ng-template
                    let-value='value'
                    let-row='row'
                    ngx-datatable-cell-template
                >
                    <span> {{ value }}</span>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
                name='Status'
                prop='status'
                [flexGrow]='2'

                [resizeable]='false'
            >
                <ng-template
                    let-value='value'
                    let-row='row'
                    ngx-datatable-cell-template
                >
                    <span 
                        class="mg-right"
                        [style.color]="value === 'PASSED' ? '#008351': '#bb1e10'"
                    >
                        <i class="bi bi-circle-fill"></i>
                    </span>
                    {{ value === 'PASSED' ? 'Bestanden' : 'Nicht bestanden' }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                             let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
                    <custom-table-footer (setLimit)="setLimit($event)" [rowCount]="rowCount" [element]="'Dateien'">
                    </custom-table-footer>

                    <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                                     [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage"
                                     [size]="pageSize" [count]="rowCount" [hidden]="!((rowCount / pageSize) > 1)"
                                     (change)="dataTable.onFooterPage($event)">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
</div>
