import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomTableFooterModule } from '@shared/components/custom-table-footer/custom-table-footer.module';
import { LoadingSpinnerModule } from '@shared/components/loading-spinner/loading-spinner.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { ImageFileListComponent } from './images-file-list.component';

@NgModule({
    declarations: [ImageFileListComponent],
    imports: [
        CommonModule,
        NgbModalModule,
        NgbModule,
        NgxDatatableModule,
        CustomTableFooterModule,
        LoadingSpinnerModule,
    ],
})
export class ZkeImageFileListModule {}
