<ngx-datatable class="material" [columnMode]="'flex'" [rows]="data" [loadingIndicator]="!data" [headerHeight]="60"
    [rowHeight]="'auto'" [footerHeight]="32" [sorts]="sortConfig" [limit]="10">
    <ngx-datatable-column name="Erstellzeitpunkt" prop="createdAt" [flexGrow]="4" [resizeable]="false">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <span *ngIf="value">{{ value | date: 'dd.MM.yy' }}, {{value | date:'HH:mm'}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Letzte Änderung" prop="changed" [flexGrow]="4" [resizeable]="false">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <span *ngIf="value">{{ value | date: 'dd.MM.yy' }}, {{value | date:'HH:mm'}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Name" prop="userFriendlyName" [flexGrow]="5" [resizeable]="false">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <span>{{ value }}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="type === 'Userspezifisch'" name="Autor" prop="owner" [flexGrow]="3"
        [resizeable]="false">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <span>{{ value }}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="type === 'Userspezifisch'" name="Berechtigung" prop="entitlement" [flexGrow]="3"
        [resizeable]="false">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <span>{{ showEntitlementText(value) }}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Kommentar" prop="note" [flexGrow]="6" [resizeable]="false">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <span class="long-text" placement="top" [ngbTooltip]="value" data-container="body"
                tooltipClass="note-tooltip">{{ value }}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Aktionen" prop="value" [flexGrow]="4" [resizeable]="false">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <div>
                <span
                    (click)="showDataPoolContent(row)"
                    title="Dateien anzeigen"
                >
                    <i class="bi {{ showIconByAuthorization(row) }} color-09d2e4"></i>
                </span>    
                <span
                    (click)="openEntitlementModal(row)"
                    *ngIf="showAuthorizationOption(row)"
                    title="Zugriffe verwalten"
                >
                    <i class="bi bi-person-fill-lock color-09d2e4 margin-left-10"></i>
                </span>
                <span
                    (click)="deleteDataPool(row)"
                    *ngIf="showDeleteOption(row)"
                    title="Datenpool löschen"
                >
                    <i class="bi bi-trash-fill color-09d2e4 margin-left-10"></i>
                </span>
            </div>
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>
