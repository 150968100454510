import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Datapool } from '@shared/models/datapool/datapool';
import { UserDatapool } from '@shared/models/datapool/user-datapool';
import { ModalResult } from '@shared/models/modal-result';
import { ApiService } from '@shared/services/api/api.service';
import { UserDataPoolService } from '@shared/services/datapool/userDataPool/user-datapool.service';
import { SecurityService } from '@shared/services/security/security.service';
import { SwitchService } from '@shared/services/switch/switch.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NewDatapoolModalComponent } from './modal/new-datapool/new-datapool-modal.component';

@Component({
    selector: 'app-test-data-list',
    templateUrl: './test-data-list.component.html',
    styleUrl: './test-data-list.component.scss'
})
export class TestDataListComponent implements OnInit, OnDestroy {
    dataPool = Datapool.TEST;
    ownData: UserDatapool[];
    userData: UserDatapool[];
    tabs = ['Meine', 'Userspezifisch'];
    active = this.tabs[0];
    ROOT_URL: string;

    private notifier = new Subject();
    private switchSetting: string;

    constructor(
        private modalService: NgbModal,
        private userDataPoolService: UserDataPoolService,
        private securityService: SecurityService,
        private switchService: SwitchService,
        private apiService: ApiService
    ) {}

    ngOnInit() {
        this.switchService.switch
            .pipe(takeUntil(this.notifier))
            .subscribe(data => {
                this.switchSetting = data;
                this.ROOT_URL = this.apiService.getUrlBasedOnPermissionAndManualSwitch(
                    'ROOT',
                    this.switchSetting
                );
                this.getUserDataPools();
            });
    }

    getUserDataPools() {
        this.userDataPoolService.getAllUserDataPools(this.ROOT_URL).subscribe(
            (dataPools: UserDatapool[]) => {
                this.ownData = dataPools.filter(
                    datapool => datapool.owner === this.securityService.user.upn
                );
                this.userData = dataPools.filter(
                    datapool => datapool.owner !== this.securityService.user.upn
                );
            },
            error => {}
        );
    }

    showNewDatapoolModal(): boolean {
        return this.active === this.tabs[0];
    }

    openNewDatapoolModal() {
        const modalRef = this.modalService.open(NewDatapoolModalComponent);

        modalRef.result.then(
            reason => {
                if (reason === ModalResult.CREATE_DATAPOOL_OK) {
                    this.getUserDataPools();
                }
            },
            error => {}
        );
    }

    ngOnDestroy(): void {
        this.notifier.next();
        this.notifier.complete();
    }
}
