import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HintComponent } from '@shared/components/hint/hint.component';

@NgModule({
    declarations: [HintComponent],
    exports: [HintComponent],
    imports: [CommonModule],
})
export class HintModule {}
