import { Location } from '@angular/common';
import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MetadataTableComponent } from '@shared/components/metadata-table/metadata-table.component';
import { permissions } from '@shared/constants/permissions';
import {
    Datapool,
    isSerienDataPool,
    isTestDataPool,
    isVorabDataPool,
} from '@shared/models/datapool/datapool';
import { UserDatapoolEntitlement } from '@shared/models/datapool/user-datapool-entitlement';
import {
    FileTypeDetail,
    getFileTypesByCategory,
} from '@shared/models/filetype/file-type';
import { FileTypeCategory } from '@shared/models/filetype/file-type-category';
import { ModalResult } from '@shared/models/modal-result';

import { VinlistFormularComponent } from './components/vinlist-formular/vinlist-formular.component';
import { WhitelistFormularComponent } from './components/whitelist-formular/whitelist-formular.component';

@Component({
    selector: 'app-data-management-control-data',
    templateUrl: './control-data.component.html',
    styleUrls: ['./control-data.component.scss'],
})
export class ControlDataComponent implements OnInit, AfterViewChecked {
    @Input() dataPool: Datapool;
    @Input() dataPoolIdentifier: string;
    @Input() dataPoolEntitlement = UserDatapoolEntitlement.WRITE;
    @ViewChild(MetadataTableComponent)
    metadataTable: MetadataTableComponent;

    fileTypeCategory = FileTypeCategory.Steuerdaten;
    fileTypeEnum: FileTypeDetail[] = getFileTypesByCategory(
        this.fileTypeCategory
    );
    importModalRef: NgbModalRef;
    requiredWriteRoles: string[];
    active = this.fileTypeEnum[0].enumType;

    constructor(
        private modalService: NgbModal,
        private location: Location,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.requiredWriteRoles = this.getDatasetWriteRoles();
    }

    ngAfterViewChecked(): void {
        this.cdr.detectChanges();
    }

    getHeadline() {
        return (
            (this.dataPoolIdentifier
                ? this.dataPoolIdentifier.split('USER_').pop() + ' - '
                : '') +
            'Dateien ' +
            this.active
        );
    }

    refreshTable() {
        this.metadataTable.getMetadataList();
    }

    uploadData() {
        if (this.active === 'Whitelisten') {
            return this.uploadWhitelist();
        } else {
            return this.uploadVinList();
        }
    }

    uploadVinList() {
        if (this.dataPool && isSerienDataPool(this.dataPool)) {
            return;
        }

        this.importModalRef = this.modalService.open(VinlistFormularComponent);

        if (this.importModalRef && this.importModalRef.componentInstance) {
            this.importModalRef.componentInstance.dataPool = this.dataPool;
            this.importModalRef.componentInstance.dataPoolIdentifier = this.dataPoolIdentifier;
        }

        if (this.importModalRef && this.importModalRef.result) {
            this.importModalRef.result.then(
                result => {
                    if (result === ModalResult.IMPORT_SUCCESSFUL) {
                        this.metadataTable.getMetadataList();
                    }
                },
                error => {}
            );
        }
    }

    uploadWhitelist() {
        if (this.dataPool && isSerienDataPool(this.dataPool)) {
            return;
        }

        this.importModalRef = this.modalService.open(
            WhitelistFormularComponent
        );

        if (this.importModalRef && this.importModalRef.componentInstance) {
            this.importModalRef.componentInstance.dataPool = this.dataPool;
            this.importModalRef.componentInstance.dataPoolIdentifier = this.dataPoolIdentifier;
        }

        if (this.importModalRef && this.importModalRef.result) {
            this.importModalRef.result.then(
                result => {
                    if (result === ModalResult.IMPORT_SUCCESSFUL) {
                        this.metadataTable.getMetadataList();
                    }
                },
                error => {}
            );
        }
    }

    goBack() {
        this.location.back();
    }

    isTestDataPool(): boolean {
        return isTestDataPool(this.dataPool);
    }

    hasUserEntitlement(): boolean {
        return this.dataPoolEntitlement !== UserDatapoolEntitlement.READ;
    }

    private getDatasetWriteRoles(): string[] {
        return isTestDataPool(this.dataPool)
            ? [permissions.PKW.DATA_TEST_WRITE, permissions.VAN.DATA_TEST_WRITE]
            : isVorabDataPool(this.dataPool)
            ? [
                  permissions.PKW.DATA_VORAB_WRITE,
                  permissions.VAN.DATA_VORAB_WRITE,
              ]
            : [];
    }
}
