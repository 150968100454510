import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { WarningComponent } from './warning.component';

@NgModule({
    declarations: [WarningComponent],
    imports: [CommonModule],
    exports: [WarningComponent],
})
export class WarningModule {}
