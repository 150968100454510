<div class="card vus-card">
    <div class="card-body">
        <div class="expander" (click)="isCollapsed = !isCollapsed">
            <span class="card-headline">Produktives Image</span>
            <span
                *ngIf="isCollapsed"
            >
                <i class="bi bi-chevron-down"></i>
            </span>
            <span
                *ngIf="!isCollapsed"
            >
                <i class="bi bi-chevron-up"></i>
            </span>
        </div>
        <div *ngIf="(isLoading) && !isCollapsed">
            <app-loading-spinner *ngIf="isLoading" [text]="getLoadingText()" [overlay]="false"></app-loading-spinner>
        </div>

        <div *ngIf="(!isLoading) && !productiveImageExist() && !isCollapsed" class="mt-5">
            <p class="text-container-empty">Aktuell ist kein produktives Image verfügbar!</p>
        </div>

        <div *ngIf="productiveImageExist() && !isCollapsed" class="row mt-4">
            <div class="col-2">
                <label class="value-label">Produktiv seit</label>
                <p>{{image?.deployedFrom | date: 'dd.MM.yy, HH:mm'}}</p>
            </div>
            <div class="col-2">
                <label class="value-label">Vorgänger-Image</label>
                <p>{{previousImage ? previousImage.imageTag : '-'}}</p>
            </div>
            <div class="col-2">
                <label class="value-label">Änderungsgrund</label>
                <p>{{image?.changeCause}}</p>
            </div>
            <div class="col-2">
                <label class="value-label">Image-Tag</label>
                <p>{{image?.imageTag}}</p>
            </div>
            <div class="col-2">
                <label class="value-label">Dateien in Image</label>
                <p>{{fileCount}} Dateien enthalten</p>
            </div>
            <div class="col-10">
                <label class="value-label">Notiz</label>
                <textarea class="form-control custom-textarea" rows="3" [disabled]="true" id="comment"
                    [value]="(image?.note ? image?.note : '')"></textarea>
            </div>
            <div class="col-2 float-bottom-right">
                <button class="btn btn-outline-primary stacked" (click)="showImageFiles()">
                    <i class="bi bi-eye-fill button-icon me-1"></i>
                    <div>Dateien anzeigen</div>
                </button>
                <button class="btn btn-outline-primary stacked-last" (click)="showImageDeploymentHistory()">
                    <i class="bi bi-calendar-fill button-icon me-1"></i>
                    <div>Historie anzeigen</div>
                </button>
            </div>
        </div>
    </div>
</div>
