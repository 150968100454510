<div class="uploadtoolInfoContainer">
    <div class="vus-title">
        <span>{{ getHeadline() }}</span>
        <div class="round-button-container">

            <span
                *ngIf="isTestDataPool()"
                (click)="goBack()"
                class="main-icon"
            >
                <i class="bi bi-arrow-left"></i>
            </span>
            <span
                (click)="refreshTable()"
                class="main-icon margin-left-10"
            >
                <i class="bi bi-arrow-clockwise"></i>
            </span>

            <ng-container *appRequiredRoles="requiredWriteRoles">
                <span
                    *ngIf="hasUserEntitlement()"
                    (click)="uploadData()"
                    class="main-icon margin-left-10"
                >
                    <i class="bi bi-plus"></i>
                </span>
            </ng-container>
        </div>
    </div>
</div>
<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="uploadToolTabset zke-nav-tabs">
    <li [ngbNavItem]="fileType.enumType" *ngFor="let fileType of fileTypeEnum; let index = index" id="Dateien {{fileType.enumType}}" class="zke-nav-item">
        <a ngbNavLink class="zke-nav-link">{{fileType.enumType}}</a>
        <ng-template ngbNavContent>
            <app-metadata-table *ngIf="dataPool" [dataPool]="dataPool" [fileType]="fileType.enumType"
                                [dataPoolEntitlement]="dataPoolEntitlement">
            </app-metadata-table>
        </ng-template>
    </li>
</ul>
<div [ngbNavOutlet]="nav"></div>
