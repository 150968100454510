<div class="smoketest-management">
    <h3 class="vus-page-title">Einstellungen</h3>
    <p class="vus-page-description">Verwalten Sie hier die Übersicht über alle Baureihen, die zugehörigen FINS und die
        Smoketest-Daten.</p>

    <div class="card vus-card">
        <div class="card-body vus-card-body">
            <div class="vus-title">
                <span>Smoketest Verwaltung</span>
                <div class="round-button-container">
                    <span
                        (click)="openDetailModal(mode.POST)"
                        class="main-icon"
                    >
                        <i class="bi bi-plus"></i>
                    </span>
                </div>
            </div>

            <app-loading-spinner *ngIf="isLoading" [text]="'Loading'" [overlay]="true"></app-loading-spinner>
            <app-smoketest-table [isLoading]="isLoading"
                                 [data]="smoketestSettingPage"
                                 (paginationChange)="paginate($event)"
                                 (detail)="openDetailModal(mode.PUT, $event)"
                                 (delete)="delete($event)">
            </app-smoketest-table>
        </div>
    </div>
</div>

