import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingSpinnerModule } from '@shared/components/loading-spinner/loading-spinner.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { CreateImageComponent } from './create-image.component';

@NgModule({
    declarations: [CreateImageComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        LoadingSpinnerModule,
        NgxDatatableModule,
        NgbModule,
    ],
    exports: [CreateImageComponent],
})
export class CreateImageModule {}
