import { DatePipe, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomTableFooterModule } from '@shared/components/custom-table-footer/custom-table-footer.module';
import { Image } from '@shared/models/image';
import { ImageDeploymentHistory } from '@shared/models/image-deployment-history';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@Component({
    selector: 'app-image-deployment-history',
    templateUrl: './image-deployment-history.component.html',
    standalone: true,
    styleUrl: './image-deployment-history.component.scss',
    imports: [
        CustomTableFooterModule,
        NgxDatatableModule,
        NgIf,
    ],
})
export class ImageDeploymentHistoryComponent {
    @Input() image: Image;
    @Input() history: ImageDeploymentHistory[];
    sortConfig = [{ prop: 'deployedFrom', dir: 'desc' }];

    constructor(
        private activeModal: NgbActiveModal,
        private datePipe: DatePipe
    ) { }

    dismiss() {
        this.activeModal.dismiss();
    }

    getDateString(value: string) {
        return this.datePipe.transform(value, 'dd.MM.yy, HH:mm');
    }
}
