<ngx-datatable #smokeTestOverviewTable
               class="material"
               [columnMode]="'flex'"
               [rows]="data?.content"
               [draggable]="false"
               [count]="data?.totalElements"
               [headerHeight]="50"
               [rowHeight]="'auto'"
               [footerHeight]="50"
               [loadingIndicator]="isLoading"
               [limit]="pagination.size"
               [sortType]="sortType"
               [externalPaging]="true"
               [externalSorting]="true"
               [sorts]="sortConfig"
               (page)="paginate($event)"
               (sort)="sort($event)">
    <ngx-datatable-column name="Baureihe" prop="baureihe" [flexGrow]="2" [resizeable]="false">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <span *ngIf="value">{{ value }}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="FIN" prop="fin" [flexGrow]="3" [resizeable]="false">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <span *ngIf="value">{{ value }}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="TTZ" prop="ttz" [flexGrow]="2" [resizeable]="false">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <span *ngIf="value">{{value}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Werkskennung" prop="werkskennung" [flexGrow]="3" [resizeable]="false">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <span *ngIf="value">{{value}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Codeleiste" prop="codes" [flexGrow]="8" [resizeable]="false">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <span *ngIf="value">{{value}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="ETM-fähig" prop="zertArt" [flexGrow]="2" [resizeable]="false">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <span
                *ngIf="value === ZertArt.ETM"
            >
                <i class="bi bi-check"></i>
            </span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Smoketest übersprungen" prop="skipable" [flexGrow]="4" [resizeable]="false">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <span
                *ngIf="value"
            >
                <i class="bi bi-check"></i>
            </span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Aktionen" [flexGrow]="2" [resizeable]="false">
        <ng-template let-row="row" let-rowIndex="rowIndex" let-expanded="expanded" ngx-datatable-cell-template>
            <div class="action-column">
                <span
                    (click)="deleteEntry(rowIndex)"
                    title="Datensatz löschen"
                >
                    <i class="bi bi-trash-fill"></i>
                </span>
                <span
                    (click)="openDetails(rowIndex)"
                    title="Details ansehen"
                >
                    <i class="bi bi-eye-fill"></i>
                </span>
            </div>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-footer>
        <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                     let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset"
                     let-isVisible="isVisible">
            <custom-table-footer (setLimit)="size($event)" [rowCount]="data?.totalElements" [element]="'Smoketest Einträge'">
            </custom-table-footer>

            <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                             [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                             [page]="curPage"
                             [size]="pageSize" [count]="rowCount" [hidden]="!((rowCount / pageSize) > 1)"
                             (change)="smokeTestOverviewTable.onFooterPage($event)">
            </datatable-pager>
        </ng-template>
    </ngx-datatable-footer>
</ngx-datatable>
