<select id='calculationInstanceSelector' class='form-control background-color' name='calculationInstance' [(ngModel)]='currentInstance' (change)="onInstanceChange($event)">
    <option *ngIf='!_instances || _instances.length === 0' [ngValue]='undefined' [selected]=true [disabled]=true>
    Kein(e) Berechnungseinheit(en) verfügbar
</option>
    <option *ngIf='_instances && _instances.length > 0' value='default' [selected]=resetState>-- Kein Image ausgewählt
        --
    </option>
    <optgroup *ngFor='let key of keys' label='{{key}}'
              [hidden]='calculationInstances.get(key) === undefined || calculationInstances.get(key).length === 0 '>
        <option *ngFor='let item of calculationInstances.get(key)' [hidden]='key !== keys[0]' [ngValue]='item'>
            {{item.name}}
        </option>
        <option [hidden]='key !== keys[1] ' value='modalVorab'>
            {{vorabInstanceName}}
        </option>
        <option [hidden]='key !== keys[2]' value='modalTestMeine'>
            {{testInstanceName}}
        </option>
        <option [hidden]='key !== keys[3]' value='modalTestUser'>
            {{testInstanceName}}
        </option>
    </optgroup>
</select>
