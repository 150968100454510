<app-loading-spinner *ngIf="isLoading" [text]="text" [overlay]="true"></app-loading-spinner>
<div class="vus-modal">
    <form [formGroup]="form">
        <div class="modal-header" *ngIf="dataPool">
            <h3 class="modal-title">{{ getHeadline() | titlecase }} Image erstellen</h3>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12 form-group" *ngIf="showSelectDatapoolTable()">
                    <label for="dataTable">Auswahl eines Test-Datenpools ({{ dataPoolType }})</label>
                    <ngx-datatable #dataTable id="dataTable" class="material datatable-scrollable-small" [columnMode]="'force'"
                        [rows]="data" [loadingIndicator]="!data" [headerHeight]="60" [rowHeight]="54" [footerHeight]="32"
                        [sorts]="sortConfig" [scrollbarV]="true" [selected]="selected"
                        [selectionType]="selectionType" (select)="onSelect()">
                        <ngx-datatable-column [headerClass]="'text-center'" [cellClass]="'text-center'" name=""
                            prop="checked" [width]="30" [resizeable]="false">
                            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                                <!-- <icon-button [buttonType]="'Radio'" [toggle]="value">
                                </icon-button> -->
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Name" prop="userFriendlyName" [width]="150" [resizeable]="false">
                            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                                <span *ngIf="value">{{ value }}</span>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Autor" prop="owner" [width]="90" [resizeable]="false">
                            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                                <span *ngIf="value">{{ value }}</span>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Notiz" prop="note" [width]="180" [resizeable]="false">
                            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                                <span class="long-text" placement="top" data-container="body">{{ value }}</span>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-footer></ngx-datatable-footer>
                    </ngx-datatable>
                </div>
                <div class="col-12">
                    <label for="note">Image-Notiz</label>
                    <textarea class="form-control" [ngClass]="{'is-invalid' : inputIsInvalid('note')}" id="note"
                        formControlName="note" rows="3"></textarea>
                    <div *ngIf="inputIsInvalid('note')" class="invalid-feedback">
                        <div>Das Notiz darf max. 255 Zeichen beinhalten!</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="warning-container">
                <p class="warning-message">Hinweis: Für dieses Image werden die aktuellen Stamm- und Steuerdaten verwendet.</p>
            </div>
            <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-secondary" (mousedown)="dismiss()">
                    <span>Abbrechen</span>
                </button>
                <button type="button" class="btn btn-primary" id="createImage" (click)="create()"
                    [disabled]="form.invalid || disableCreate()">
                    <span>Image erstellen</span>
                </button>
            </div>
        </div>
    </form>
</div>
