import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomTableFooterModule } from '@shared/components/custom-table-footer/custom-table-footer.module';
import { MetadataTableComponent } from '@shared/components/metadata-table/metadata-table.component';
import { RoleDirectiveModule } from '@shared/directives/role.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
    imports: [
        CommonModule,
        NgxDatatableModule,
        RoleDirectiveModule,
        NgbModule,
        CustomTableFooterModule,
    ],
    exports: [MetadataTableComponent],
    declarations: [MetadataTableComponent],
    providers: [],
})
export class MetadataTableModule {}
