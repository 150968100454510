<div class="test-data-management">
    <h3 class="vus-page-title">Datenverwaltung - Testdaten</h3>


    <div class="card vus-card">
        <div class="card-body vus-card-body">
            <div class="vus-title">
                <span>Auswahl eines Test-Datenpools</span>
                <div class="round-button-container" *ngIf="showNewDatapoolModal()">
                    <span
                        (click)="openNewDatapoolModal()"
                        class="main-icon"
                    >
                        <i class="bi bi-plus"></i>
                    </span>
                </div>
            </div>
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="uploadToolTabset zke-nav-tabs">
                <li [ngbNavItem]="tabs[0]" class="zke-nav-item">
                    <a ngbNavLink class="zke-nav-link">Meine</a>
                    <ng-template ngbNavContent>
                        <app-test-data-list-datatable (refreshUserdataPools)="getUserDataPools()" [data]="ownData"
                                                      [type]="active" [apiUrl]="ROOT_URL">
                        </app-test-data-list-datatable>
                    </ng-template>
                </li>
                <li [ngbNavItem]="tabs[1]" class="zke-nav-item">
                    <a ngbNavLink class="zke-nav-link">Userspezifisch</a>
                    <ng-template ngbNavContent>
                        <app-test-data-list-datatable (refreshUserdataPools)="getUserDataPools()" [data]="userData"
                                                      [type]="active" [apiUrl]="ROOT_URL">
                        </app-test-data-list-datatable>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav"></div>
        </div>
    </div>
</div>
