<div class="notification-detail-modal vus-modal">
    <form>
        <div class="modal-header close-top-right">
            <div *ngIf="topic">
                <h3 class="modal-title">{{topic.name}} - {{topic.description}}</h3>
                <p class="modal-subtitle">Verwalten Sie, welche Nutzer Fehlerbenachrichtigungen für diesen Fall erhalten sollen.</p>
            </div>
            <button type="button" class="btn close" aria-label="Close"
                    (click)="dismiss()">
                <span
                    icon="times"
                >
                    <i class="bi bi-x"></i>
                </span>
            </button>
        </div>
        <div class="modal-body">

            <ng-container *appRequiredRoles="requiredWriteRoles">
                <form [formGroup]="form">
                    <div class="email-form">
                        <div style="flex: 1;">
                            <label for="email">Weitere E-Mail Adresse hinzufügen</label>
                            <input
                                type="email"
                                autocomplete="off"
                                class="form-control"
                                id="email"
                                placeholder="Hier eingeben"
                                formControlName="email"
                                [class.is-invalid]="form.invalid && form.get('email').touched"
                            />
                        </div>
                        <div class="submit-container">
                            <button class="btn btn-primary" (click)="save()">
                                <span>Anfrage versenden</span>
                            </button>
                        </div>
                    </div>
                    <div *ngIf="form.get('email').invalid && form.get('email').touched" class="invalid-feedback">
                        <div *ngIf="form.get('email').errors.required">Pflichtfeld!</div>
                        <div *ngIf="form.get('email').errors.pattern">Keine gültige E-Mail Adresse!</div>
                    </div>
                </form>
            </ng-container>

            <ngx-datatable #subscriberTable
                           class="material"
                           [columnMode]="'flex'"
                           [rows]="subscribers"
                           [draggable]="false"
                           [headerHeight]="50"
                           [rowHeight]="'auto'"
                           [footerHeight]="50"
                           [sorts]="sortConfig">
                <ngx-datatable-column name="E-Mail Adresse" prop="email" [flexGrow]="2" [resizeable]="false">
                    <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                        <span>{{ value }}</span>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Status" prop="status" [flexGrow]="2" [resizeable]="false">
                    <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                        <span>{{ topicStatus[value] }}</span>
                    </ng-template>
                </ngx-datatable-column>
                <ng-container *appRequiredRoles="requiredWriteRoles">
                    <ngx-datatable-column name="Aktionen" [flexGrow]="1" [resizeable]="false">
                        <ng-template let-row="row" let-rowIndex="rowIndex" let-expanded="expanded" ngx-datatable-cell-template>
                            <div class="action-column" *ngIf="topicStatus[row.status] !== topicStatus.PENDINGCONFIRMATION">
                                <span
                                    (click)="deleteEntry(row)"
                                    title="Eintrag löschen"
                                >
                                    <i class="bi bi-trash-fill"></i>
                                </span>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                </ng-container>
                <ngx-datatable-footer>
                    <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                                 let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset"
                                 let-isVisible="isVisible">
                        <custom-table-footer [rowCount]="rowCount" [element]="'Kontakte'">
                        </custom-table-footer>

                        <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                                         [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                                         [page]="curPage"
                                         [size]="pageSize" [count]="rowCount" [hidden]="!((rowCount / pageSize) > 1)"
                                         (change)="subscriberTable.onFooterPage($event)">
                        </datatable-pager>
                    </ng-template>
                </ngx-datatable-footer>
            </ngx-datatable>
        </div>
    </form>
</div>
