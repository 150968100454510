<ngx-datatable #dataTable class="material" [columnMode]="'flex'" [rows]="data" [loadingIndicator]="!data"
    [headerHeight]="50" [rowHeight]="56" [footerHeight]="50" [sorts]="sortConfig" [limit]="dataTableLimit">
    <ngx-datatable-column name="Baureihe" prop="baureihe" [flexGrow]="2" *ngIf="hasBaureihe()" [resizeable]="false">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <span>{{ value }}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Dateiname" prop="fileName" [flexGrow]="hasReferenceName() ? 4 : 5"
                          [canAutoResize]="true" [resizeable]="false">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <span>{{ value }}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Uploadzeitpunkt" prop="uploadedAt" [flexGrow]="hasBaureihe() ? 3 : 4"
                          [canAutoResize]="true" [resizeable]="false">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <span *ngIf="value">{{ value | date: 'dd.MM.yy - HH:mm' }}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Erstellzeitpunkt" prop="createdAt" [flexGrow]="hasBaureihe() ? 3 : 4"
                          [canAutoResize]="true" [resizeable]="false">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <span *ngIf="value">{{ value | date: 'dd.MM.yy - HH:mm' }}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Referenzname" prop="fileRef" [flexGrow]="4" [canAutoResize]="true"
                          *ngIf="hasReferenceName()" [resizeable]="false">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <span>{{ value }}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Kommentar" prop="note" [flexGrow]="7" [canAutoResize]="true" [resizeable]="false">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <span class="long-text" placement="top" [ngbTooltip]="value" data-container="body"
                tooltipClass="note-tooltip">{{ value }}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="User" prop="user" [flexGrow]="3" [canAutoResize]="true" [resizeable]="false">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <span class="long-text" placement="top" [ngbTooltip]="value" data-container="body"
                  tooltipClass="note-tooltip">{{ value }}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Aktionen" prop="value" [flexGrow]="2" [canAutoResize]="false" [resizeable]="false">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <ng-container>
                <span
                    (click)="exportFile(row)"
                    title="Datei downloaden"
                >
                    <i class="bi bi-download"></i>
                </span>
            </ng-container>
            <ng-container *appRequiredRoles="requiredWriteRole">
                <span
                    *ngIf="isDeletable() && hasUserEntitlement()"
                    (click)="deleteMetadata(row)"
                    title="Datei löschen"
                >
                    <i class="bi bi-trash-fill"></i>
                </span>
            </ng-container>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-footer>
        <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
            let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
            <custom-table-footer (setLimit)="setLimit($event)" [rowCount]="rowCount" [element]="'Dateien'">
            </custom-table-footer>

            <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage"
                [size]="pageSize" [count]="rowCount" [hidden]="!((rowCount / pageSize) > 1)"
                (change)="dataTable.onFooterPage($event)">
            </datatable-pager>
        </ng-template>
    </ngx-datatable-footer>
</ngx-datatable>
