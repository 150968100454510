import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserDatapool } from '@shared/models/datapool/user-datapool';
import { ModalResult } from '@shared/models/modal-result';
import { ApiService } from '@shared/services/api/api.service';
import { UserDataPoolService } from '@shared/services/datapool/userDataPool/user-datapool.service';
import { ErrorHandlerService } from '@shared/services/error-handler/error-handler.service';
import { SwitchService } from '@shared/services/switch/switch.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-new-datapool-modal',
    templateUrl: './new-datapool-modal.component.html',
    styleUrl: './new-datapool-modal.component.scss',
})
export class NewDatapoolModalComponent implements OnInit, OnDestroy {
    form: UntypedFormGroup;
    isLoading = false;
    loadingText = 'Der neue Datenpool wird erstellt...';
    isNameExisting = false;
    ROOT_URL: string;

    private notifier = new Subject();
    private switchSetting: string;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private activeModal: NgbActiveModal,
        private userDataPoolService: UserDataPoolService,
        private messageHandler: ErrorHandlerService,
        private switchService: SwitchService,
        private apiService: ApiService
    ) {}

    ngOnInit() {
        this.switchService.switch
            .pipe(takeUntil(this.notifier))
            .subscribe(data => {
                this.switchSetting = data;
                this.ROOT_URL = this.apiService.getUrlBasedOnPermissionAndManualSwitch(
                    'ROOT',
                    this.switchSetting
                );
            });

        this.form = this.formBuilder.group({
            name: [''],
            note: ['', Validators.maxLength(255)],
        });
    }

    validateName() {
        this.isNameExisting = false;

        this.userDataPoolService.getAllUserDataPools(this.ROOT_URL).subscribe(
            (dataPools: UserDatapool[]) => {
                for (const dataPool of dataPools) {
                    if (
                        dataPool.userFriendlyName ===
                        this.form.controls.name.value
                    ) {
                        this.isNameExisting = true;
                        break;
                    }
                }

                this.isNameExisting
                    ? (this.isLoading = false)
                    : this.saveNewDataPool();
            },
            error => {
                this.isLoading = false;
            }
        );
    }

    createDataPool() {
        this.isLoading = true;
        this.form.controls.name.markAsTouched();
        this.validateName();
    }

    isNameValid(): boolean {
        return (
            !this.form.controls.name.touched ||
            (this.form.controls.name.value !== '' && !this.isNameExisting)
        );
    }

    inputIsInvalid(control: string): boolean {
        return this.form.get(control).errors && this.form.get(control).touched;
    }

    disableSaveButton(): boolean {
        return (
            this.form.controls.name.value === '' || this.inputIsInvalid('note')
        );
    }

    dismiss() {
        this.activeModal.dismiss();
    }

    private saveNewDataPool() {
        this.userDataPoolService
            .saveNewUserDataPool(
                this.ROOT_URL,
                this.form.controls.name.value,
                this.form.controls.note.value
            )
            .subscribe(
                response => {
                    this.isLoading = false;
                    this.messageHandler.showSuccess(
                        'Erfolgreich',
                        `Der Datenpool ${this.form.controls.name.value} wurde erfolgreich erstellt`
                    );
                    this.activeModal.close(ModalResult.CREATE_DATAPOOL_OK);
                },
                error => {
                    this.isLoading = false;
                }
            );
    }

    ngOnDestroy(): void {
        this.notifier.next();
        this.notifier.complete();
    }
}
