import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReasonDialogComponent } from '@shared/components/reason-dialog/reason-dialog.component';

@NgModule({
    declarations: [ReasonDialogComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    exports: [ReasonDialogComponent],
})
export class ReasonDialogModule {}
