<app-loading-spinner *ngIf="isLoading" [text]="loadingText" [overlay]="true"></app-loading-spinner>
<div class="container-fluid vus-modal">
    <form [formGroup]="form">
        <div class="modal-header">
            <h3 class="modal-title">Neuer Test-Datenpool</h3>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12 form-group">
                    <label for="name">Name *</label>
                    <input autocomplete="off" id="name" class="form-control background-color" type="text" formControlName="name"
                        aria-describedby="name" [ngClass]="{'is-invalid' : !isNameValid()}">
                    <div *ngIf="!isNameValid()" class="invalid-feedback">
                        <div *ngIf="(this.form.controls.name.value === '')">Pflichtfeld!</div>
                        <div *ngIf="isNameExisting">Datenpool mit diesem Namen existiert bereits!</div>
                    </div>
                </div>
                <div class="col-12 form-group mb-0">
                    <label for="note">Notiz</label>
                    <textarea class="form-control background-color" [ngClass]="{'is-invalid' : inputIsInvalid('note')}" id="note"
                        formControlName="note" rows="3"></textarea>
                    <div *ngIf="inputIsInvalid('note')" class="invalid-feedback">
                        <div>Das Notiz darf max. 255 Zeichen beinhalten!</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-secondary" (mousedown)="dismiss()">
                    <span>Abbrechen</span>
                </button>
                <button class="btn btn-primary" [disabled]="disableSaveButton()"
                    (click)="createDataPool()">
                    <span>Datenpool erzeugen</span>
                </button>
            </div>
        </div>
    </form>
</div>
