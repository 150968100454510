import { Location } from '@angular/common';
import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MetadataTableComponent } from '@shared/components/metadata-table/metadata-table.component';
import { permissions } from '@shared/constants/permissions';
import {
    Datapool,
    isSerienDataPool,
    isTestDataPool,
    isVorabDataPool,
} from '@shared/models/datapool/datapool';
import { UserDatapoolEntitlement } from '@shared/models/datapool/user-datapool-entitlement';
import {
    FileType,
    FileTypeDetail,
    getFileTypesByCategory,
} from '@shared/models/filetype/file-type';
import { FileTypeCategory } from '@shared/models/filetype/file-type-category';
import { ModalResult } from '@shared/models/modal-result';

import { UploadFormularComponent } from './upload-formular/upload-formular.component';

@Component({
    selector: 'app-data-management-master-data',
    templateUrl: './master-data.component.html',
    styleUrls: ['./master-data.component.scss'],
})
export class MasterDataComponent implements OnInit, AfterViewChecked {
    @Input() dataPool: Datapool;
    @Input() dataPoolIdentifier: string;
    @Input() dataPoolEntitlement = UserDatapoolEntitlement.WRITE;
    @ViewChild(MetadataTableComponent)
    metadataTable: MetadataTableComponent;

    fileTypeCategory = FileTypeCategory.Stammdaten;
    fileTypeEnum: FileTypeDetail[] = getFileTypesByCategory(
        this.fileTypeCategory
    );
    requiredWriteRoles: string[];
    active = this.fileTypeEnum[0].enumType;

    constructor(
        private modalService: NgbModal,
        private location: Location,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.requiredWriteRoles = this.getDatasetWriteRoles();
    }

    ngAfterViewChecked(): void {
        this.cdr.detectChanges();
    }

    getHeadline(): string {
        return (
            (this.dataPoolIdentifier
                ? this.dataPoolIdentifier.split('USER_').pop() + ' - '
                : '') +
            'Dateien ' +
            this.active
        );
    }

    refreshTable() {
        this.metadataTable.getMetadataList();
    }

    uploadData() {
        if (this.dataPool && isSerienDataPool(this.dataPool)) {
            return;
        }

        const modalRef = this.modalService.open(UploadFormularComponent);
        if (modalRef) {
            if (modalRef.componentInstance) {
                modalRef.componentInstance.dataPool = this.dataPool;
                modalRef.componentInstance.fileType = this.setFileTypeByCard();
                modalRef.componentInstance.dataPoolIdentifier = this.dataPoolIdentifier;
            }

            if (modalRef.result) {
                modalRef.result.then(
                    reason => {
                        if (reason === ModalResult.IMPORT_SUCCESSFUL) {
                            this.metadataTable.getMetadataList();
                        }
                    },
                    error => {}
                );
            }
        }
    }

    goBack() {
        this.location.back();
    }

    isTestDataPool(): boolean {
        return isTestDataPool(this.dataPool);
    }

    hasUserEntitlement(): boolean {
        return this.dataPoolEntitlement !== UserDatapoolEntitlement.READ;
    }

    private setFileTypeByCard(): FileType {
        if (this.active === 'Attribute CSV') {
            return FileType.ATTRIBUTE;
        } else {
            return FileType.TRANSITION;
        }
    }

    private getDatasetWriteRoles(): string[] {
        return isTestDataPool(this.dataPool)
            ? [permissions.PKW.DATA_TEST_WRITE, permissions.VAN.DATA_TEST_WRITE]
            : isVorabDataPool(this.dataPool)
            ? [
                  permissions.PKW.DATA_VORAB_WRITE,
                  permissions.VAN.DATA_VORAB_WRITE,
              ]
            : [];
    }
}
