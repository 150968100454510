import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalculateInstanceTableComponent } from '@shared/components/calculate-instance-selector/calculate-instance-table/calculate-instance-table.component';
import { CalculationInstance } from '@shared/models/calculation-instance';
import { UserDatapoolEntitlement } from '@shared/models/datapool/user-datapool-entitlement';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'shared-calculate-instance-selector',
    templateUrl: './calculate-instance-selector.component.html',
    styleUrl: './calculate-instance-selector.component.scss'
})
export class CalculateInstanceSelectorComponent {
    @Output() selectedInstance = new EventEmitter<CalculationInstance>();
    vorabInstanceName: string = 'Image auswählen...';
    testInstanceName: string = 'Image auswählen...';
    currentInstance: CalculationInstance;
    calculationInstances = new Map<string, CalculationInstance[]>();
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    _instances: CalculationInstance[];
    keys = ['SERIEN', 'VORAB', 'TEST - Meine', 'TEST - Userspezifische'];

    constructor(private modalService: NgbModal) {}

    @Input()
    set instances(instances: CalculationInstance[]) {
        this._instances = instances;
        this.mapInstances();
        this.selectedInstance.emit(this.currentInstance);
    }

    @Input()
    set reset(counter: number) {
        this.resetSelector();
    }

    emitInstance(instance: any) {
        this.selectedInstance.emit(instance);
    }

    private mapInstances() {
        if (!this._instances) {
            return;
        }

        this._instances.forEach(value => {
            // TEST = Old Data; Has to be filtered from this selection!
            if (value.stage !== 'TEST') {
                const key =
                    value.stage === 'USER'
                        ? value.entitlement === UserDatapoolEntitlement.OWNER
                            ? this.keys[2]
                            : this.keys[3]
                        : value.stage;

                const collection = this.calculationInstances.get(key);
                if (!collection) {
                    this.calculationInstances.set(key, [value]);
                } else {
                    collection.push(value);
                }
            }
        });
    }

    private resetSelector() {
        const serienCollection = this.calculationInstances.get('SERIEN');
        if (serienCollection) {
            this.currentInstance = serienCollection[0];
        }
    }

    onInstanceChange(event: any) {
        const selectedValue = event.target.value;
        if (selectedValue === 'modalVorab') {
            this.showCalculateInstanceTable(
                this.calculationInstances.get('VORAB')
            );
        } else if (selectedValue === 'modalTestMeine') {
            this.showCalculateInstanceTable(
                this.calculationInstances.get('TEST - Meine')
            );
        } else if (selectedValue === 'modalTestUser') {
            this.showCalculateInstanceTable(
                this.calculationInstances.get('TEST - Userspezifische')
            );
        } else {
            this.emitInstance(this.currentInstance);
        }
    }

    showCalculateInstanceTable(data: CalculationInstance[]) {
        const modalRef = this.modalService.open(
            CalculateInstanceTableComponent,
            {
                windowClass: 'modalVeryBig',
                backdrop: 'static',
                keyboard: false,
            }
        );
        modalRef.componentInstance.instances = data;
        modalRef.result.then(result => {
            if (result) {
                if (result.stage == 'VORAB') {
                    this.vorabInstanceName = result.name;
                } else {
                    this.testInstanceName = result.name;
                }
                this.emitInstance(result);
            }
        });
    }
}
