import { DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppAuthGuard } from './app.authguard';
import { AppComponent } from './app.component';
import { ForbiddenPageModule } from './core/forbidden-page/forbidden-page.module';
import { HeaderModule } from './core/header/header.module';
import { LoginComponent } from './core/login/login.component';
import { SidebarModule } from './core/sidebar/sidebar.module';
import { UnauthorizedModalModule } from './core/unauthorized-modal/unauthorized-modal.module';
import { GlobalHttpInterceptor } from './core/utils/global-http-interceptor';
import { DataManagementModule } from './modules/data-management/data-management.module';
import { ExpertToolModule } from './modules/expert-tool/expert-tool.module';
import { ImageManagementModule } from './modules/image-management/image-management.module';
import { SettingsModule } from './modules/settings/settings.module';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

@NgModule({
    declarations: [AppComponent, LoginComponent],
    imports: [
        AngularSvgIconModule,
        ForbiddenPageModule,
        BrowserModule,
        AppRoutingModule,
        NgbModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            positionClass: 'toast-top-right',
            preventDuplicates: true,
            timeOut: 6000,
        }),
        HeaderModule,
        SidebarModule,
        UnauthorizedModalModule,
        ExpertToolModule,
        SettingsModule,
        ImageManagementModule,
        DataManagementModule,
    ],
    providers: [
        AppAuthGuard,
        DatePipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: GlobalHttpInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor() {
    }
}
