import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataPoolSelectorModule } from '@shared/components/datapool-selector/datapool-selector.module';
import { MetadataTableModule } from '@shared/components/metadata-table/metadata-table.module';
import { RoleDirectiveModule } from '@shared/directives/role.module';

import { VinListFormularModule } from './components/vinlist-formular/vinlist-formular.module';
import { WhitelistFormularModule } from './components/whitelist-formular/whitelist-formular.module';
import { ControlDataComponent } from './control-data.component';

@NgModule({
    declarations: [ControlDataComponent],
    imports: [
        CommonModule,
        DataPoolSelectorModule,
        FormsModule,
        NgbModule,
        VinListFormularModule,
        WhitelistFormularModule,
        MetadataTableModule,
        RoleDirectiveModule,
    ],
    exports: [ControlDataComponent],
})
export class ControlDataModule {}
